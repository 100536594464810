@import './variables';

.hero {
    background-color: #2d2f33;
    height: 100vh;
    width: 100%;

    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .section-info {
        width: 40%;
        max-width: 30rem;
        padding: 40px 0px 40px 50px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .horizontal-line {
            width: 90%;
            max-width: 25rem;
        }

        %text-format {
            font-family: bilo, sans-serif;
            font-weight: 100;

            margin-left: $hero-info-border-size;
        }

        h1 {
            text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.7);
            @extend %text-format;

            font-size: 4rem;
            color: #fff;

            margin-bottom: 20px;
        }

        p {
            @extend %text-format;
            max-width: 90%;

            font-size: 1.2rem;
            line-height: 1.5;
            color: #cfcfcf;

            // text-align: justify;
        }

        a {
            width: 16rem;
            height: 5rem;
            background-color: #3a3743;

            display: flex;
            align-items: center;
            justify-content: center;

            filter: drop-shadow(7px 7px 22px rgba(0, 0, 0, 0.44));

            font-family: Candara;
            font-size: 2rem;
            text-decoration: none;
            color: rgba(255, 255, 255, 0.6);

            border: 3px solid rgba(92, 99, 233, 0.8);
            border-radius: 0.8rem;
            margin: 15% $hero-info-border-size 0rem;

            cursor: pointer;
        }

    }

    .section-image {
        width: 60%;

        display: flex;
        align-items: center;

        .graphic-wrapper {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;

            overflow: hidden;

            .overlayed-image {
                width: 100%;
                grid-column: 1/2;
                grid-row: 1/2;
            }

            %overlayed-scaling-image {
                @extend .overlayed-image;
                position: relative;
                transition: 0.4s ease;

                &:hover {
                    transform: translateX(-5%) scale(1.05);
                }
            }

            #holo-sidebar {
                @extend %overlayed-scaling-image;
                width: 20%;
                top: 10%;
                left: 27%;
            }


            #holo-panel {
                @extend %overlayed-scaling-image;
                width: 32%;
                top: 20%;
                left: 44%;
            }

        }
    }

}

.horizontal-line {
    width: 100%;
    height: 2px;
    background-color: rgb(255, 255, 255);
    opacity: 0.35;
    margin: 25px 0px;
}

.image-blurred-edge {
    background-image: url('http://lorempixel.com/200/200/city/9');
    /* you need to match the shadow color to your background or image border for the desired effect*/
    box-shadow: 0 0 8px 8px white inset;
}