.mlgui-graphic-section {
    justify-self: end;
    align-self: center;

    height: 100%;

    img {
        height: 60%;
        position: relative;
        top: 5rem;
    }

}