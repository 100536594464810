$contact-primary-text: #1a1b1f;
$contact-secondary-text: #95a2b2;

.contact-container {
    height: 100vh;
    width: 60%;

    margin: 5rem;

    margin-left: max(5rem, 8%);

    h2 {
        font-size: 2rem;
        font-weight: normal;
        // font-family: Inter;
        color: $contact-primary-text;
        margin: 5px;

    }

    p {
        font-size: 1rem;
        font-weight: normal;
        color: $contact-secondary-text;
    }

    form {
        p {
            font-size: 1rem;
            font-weight: normal;
            color: $contact-primary-text;
            margin: 1rem 0rem 0.5rem;
        }

        .id-form-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 2fr;
            column-gap: 2rem;
        }

        .contact-input {
            width: 100%;
            height: 2.4rem;
            padding: 0.3rem 0px 0.3rem 1.5rem;
            border: 1px solid $contact-secondary-text;
            border-radius: 0px;

            font-size: 1rem;
            color: $contact-primary-text;

            opacity: 0.6;

            &:focus {
                border: 1px solid $contact-secondary-text;
            }
        }

        .submit-button {
            width: 6rem;
            height: 2.5rem;

            background-color: $contact-primary-text;

            font-size: 1rem;
            font-family: bilo;
            font-weight: 300;
            color: white;

            margin-top: 1rem;

            cursor: pointer;

            transition: 0.4s ease;

            &:hover {
                filter: opacity(80%);
            }
        }

    }
}