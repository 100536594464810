.two-column-project {
    width: 100%;
    height: 100%;
    background-color: #ffffff;

    display: grid;
    grid-template-columns: 1fr 1fr;

    border-radius: $panel-border-radius $panel-border-radius 0px 0px;

    transition: all 0.3s ease;

    .info-section {

        margin: 25% 2rem 0rem 3rem;
        margin-top: 25%;

        .links-container {
            width: 11rem;
            height: 2.8rem;

            display: flex;
            justify-content: space-around;
            align-items: center;

            background-color: #ffffff;

            border-radius: 1rem;
            padding: 0.6rem;
            margin-top: 3rem;

            filter: drop-shadow(-2px 3px 16px rgba(0, 0, 0, 0.1));

            img {
                width: auto;
                height: 85%;
                // object-fit: cover;
            }
        }

        h2 {

            font-size: 2.2rem;
            font-weight: bold;
            color: $nmt-primary-color;
        }

        p {
            font-size: $nmt-small-font;
            color: $nmt-primary-color;
        }


    }

    .graphic-section {
        justify-self: end;
        align-self: center;

        img {
            // height: 60%
            width: 100%;

            position: relative;
            bottom: 5rem;
        }
    }
}