@import './variables';
@import './bert_project';
@import './nmt_project';
@import './mlgui_project';
@import './classification_project.scss';

.projects {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;

    .sidebar {
        width: $sidebar-ratio;
        width: 20rem;
        height: 90%;
        background-color: white;

        border-radius: 0px $panel-border-radius $panel-border-radius 0px;


        filter: drop-shadow(0px 3px 10px rgba(190, 190, 190, 0.562));

        .blocks-container {
            width: 75%;
            height: 80%;
            display: grid;
            grid-template-columns: 1fr;
            grid-auto-flow: row;
            grid-auto-rows: 8rem;

            grid-gap: 1rem;

            padding-top: 3rem;
            padding-bottom: 3rem;

            .sidebar-animation-wrapper {
                transition: all 0.3s ease;

                &.active-block {
                    transform: translateX(40%);
                    filter: none;
                }
            }

            .sidebar-block {
                width: 100%;
                height: 100%;

                background-color: #ffffff;

                display: flex;
                justify-content: center;

                border: 1px solid rgba(0, 0, 0, 0.19);
                border-radius: 0.8rem;
                margin-left: 10%;

                cursor: pointer;

                overflow: hidden;

                transition: all 0.3s ease;

                &:hover {
                    transform: translateX(+2%);
                }

                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }


            }
        }

    }

    .body {
        height: 100%;
        width: 100% - $sidebar-ratio ;

        .projects-panel {
            width: 75%;
            height: 100%;

            margin-left: 12%;

            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;

            padding: 0rem 1rem;
            overflow: hidden;
            // overflow: visible;
            // overflow: scroll;

            .project-wrapper {
                // Animate this element
                height: 110vh;
                background-color: rgba($bert-primary-color, 0.8);
                background-clip: padding-box;

                filter: drop-shadow(0px 3px 10px rgba(190, 190, 190, 0.3));
                border-radius: $panel-border-radius;
                margin-top: 2rem;

                grid-column: 1/2;
                grid-row: 1/2;

                transition: 0.5s ease;
                // transition: 1s ease;

                &.before {
                    transform: translateY(-110%);
                }

                &.active {
                    transform: translateY(0%);
                }

                &.after {
                    transform: translateY(110%);
                }

            }
        }

    }

}