.classification-project {
    width: 100%;
    height: 100%;

    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    border-radius: $panel-border-radius $panel-border-radius 0px 0px;


    .text-container {
        width: 60%;


        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            font-size: 2rem;
            font-weight: bold;
            color: black;
            margin-bottom: 1rem;
            margin-bottom: 0px;
        }

        p {
            font-size: 1.2rem;
            color: #7E7E7E;
            margin-bottom: 1rem;
        }
    }

    .cards-container {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;

        margin: 5%;

        %card {
            background-color: #ffffff;

            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            padding: 1rem;
            border-radius: 1rem;
            margin: 1rem 0rem 3rem 0rem;

            text-align: center;

            h4 {
                font-size: 1.7rem;
                font-weight: 600;
                color: #272727;
                margin: 0px;
                margin-bottom: 5px;
            }

            p {
                font-size: 1rem;
                color: $classification-secondary-text;
                margin: 0px;
            }

            img {
                margin-top: 1rem;
                width: 100%;
                height: auto;
                border-radius: 1rem;

                filter: drop-shadow(0px 3px 3px rgba(190, 190, 190, 0.25));
            }
        }

        .big-card {
            @extend %card;

            width: $classification-big-card-width;
            height: $classification-big-card-height;

            filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.24));

        }

        .small-card {
            @extend %card;
            width: $classification-small-card-width;
            height: $classification-small-card-height;
            // margin: 0px;


            filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));
        }
    }
}